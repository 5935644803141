/* eslint-disable import/extensions */
import React from 'react';
import { auth } from '@redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
	root: {
		color: theme.palette.primary.main,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		autologinDemoRequest: (authdemoid, authtoken, expired) => dispatch(auth.actions.autologinDemoRequest({authdemoid, authtoken, expired})),
	},
});

class AutoLoginDemo extends React.Component {
	componentDidMount() {
		this.props.actions.autologinDemoRequest(this.props.authdemoid, this.props.authtoken, this.props.expired);
	}

	render() {
		const { classes } = this.props;
		return (
			<CircularProgress
				classes={classes}
				size={58}
			/>
		);
	}
}

AutoLoginDemo.propTypes = {
	actions: PropTypes.object.isRequired,
	authdemoid: PropTypes.string.isRequired,
	authtoken: PropTypes.string.isRequired,
	expired: PropTypes.string.isRequired,
};

export default compose(
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(AutoLoginDemo);
