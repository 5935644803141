import { providerWithAuth, provider } from '@api';
import { env } from '@config';

export const autologon = autologin => providerWithAuth().post('/account/api_autologon', { autologin });
export const loginLocal = (
    username,
    password,
    captchaToken,
    securityCode
) => providerWithAuth().post(
        '/account/apiui_logon',
        {
            username,
            password,
            captchaToken,
            securityCode
        });
export const changeLanguage = lang => provider.get(`/account/apiui_changeculture/${lang}`);
export const logout = () => providerWithAuth().get('/account/api_logoff');
export const pingauth = () =>	providerWithAuth().get('/account/apiui_pingauth');
export const sendconfirmationcode = (phone, reason) => providerWithAuth().post('/account/api_sendconfirmationcode', { phone, reason });
export const version = () => providerWithAuth().get(`/account/api_version/${env.appName ? env.appName : ''}`);
export const sendemailconfirmationcode = (email, reason) => providerWithAuth().post('/account/api_sendemailconfirmationcode', { email, reason });
export const autologondemo = ({authdemoid, authtoken, expired}) => providerWithAuth().post('/account/api_autologondemo', { authdemoid, authtoken, expired });
