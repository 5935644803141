import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { SvgIcon, Button } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { constants, env } from '@config';
import { app, agent, userAccounts, notifications } from '@redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import {
	AccountSelection,
	SidebarLogo,
	AccountActions,
	EmptyLinks,
	QuickLinks,
	ReportsQuickLinks,
	DocumentsQuickLinks,
	AccountActionsJ2TX,
} from './components';

const styles = theme => ({
	root: {
		overflow: 'hidden',
	},
	actionsDivider: {
		padding: '0 24px',
	},
	buttonsWrapper: {
		margin: '16px 24px',
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		partnerCabinetRedirect: () => dispatch(agent.actions.partnerCabinetRedirect.request()),
		agentRegistration: () => dispatch(agent.actions.agentRegistration.request({
			AgentRewardType: 'IB',
			ApplyAgreement: true,
		})),
		showAgentRegistrationFormModal: () => dispatch(app.actions.showAgentRegistrationFormModal()),
		hideAgentRegistrationFormModal: () => dispatch(app.actions.hideAgentRegistrationFormModal()),
	},
});

const mapStateToProps = state => ({
	accounts: userAccounts.selectors.accounts(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	agentPartnerCabinetRedirect: agent.selectors.partnerCabinetRedirect(state),
	userInfo: userAccounts.selectors.userInfo(state),
	userInfoLoading: userAccounts.selectors.loading(state) || userAccounts.selectors.userInfo(state).submitting,
	notifications: notifications.selectors.notifications(state).items,
	areNotificationsLoading: notifications.selectors.loading(state),
});

class SidebarContent extends React.Component {
	componentWillUnmount() {
		if (!this.props.location.hash) {
			// if no Modals when show main scroll for mobiles
			document.querySelector('body').style.overflow = 'auto';
		} else {
			// otherwise - hide main scroll for mobiles
			document.querySelector('body').style.overflow = 'hidden';
		}
	}

	showAgentRegistrationFormModal = () => {
		this.props.actions.showAgentRegistrationFormModal();
	}
	render() {
		const {
			actions,
			classes,
			match,
			agentPartnerCabinetRedirect,
			userInfo,
			userInfoLoading,
			notifications,
			areNotificationsLoading,
		} = this.props;

		const hasAlertDebt = notifications?.some(item => item.AlertType === 'alert');

		return (
			<div className={classes.root}>
				{env.firm === constants.FIRM_J2TX  || userInfo.Guid === null
					? <SidebarLogo />
					: <AccountSelection />
				}
				<div className={classes.action}>
					<div className={classes.accountActions}>
						{env.firm === constants.FIRM_J2TX ? <AccountActionsJ2TX /> : <AccountActions /> }
					</div>
					<div className={classes.actionsDivider} />
					{(env.firm === constants.LIME_FIN)
							&& (
								<div className={classes.quickLinks}>
									<Switch>
										<Route exact path={`${match.url}/settings`} component={EmptyLinks} />
										<Route exact path={`${match.url}/reports`} component={ReportsQuickLinks} />
										<Route exact path={`${match.url}/features`} component={EmptyLinks} />
										<Route exact path={`${match.url}/documents`} component={DocumentsQuickLinks} />
										<Route exact path={`${match.url}/cashmanagement`} component={EmptyLinks} />
										<Route exact path={`${match.url}/thirdpartytools`} component={EmptyLinks} />
										<Route path={`${match.url}`} component={QuickLinks} />
									</Switch>
								</div>
							)}
				</div>
				<div className={classes.buttonsWrapper}>
					{[constants.LIME_LME].some(x => x === env.firm) && !userInfo.IsAgent && userInfo.Guid && !areNotificationsLoading
							&& (
								<Button
									className="QA-BecomePartner"
									fullWidth
									onClick={this.showAgentRegistrationFormModal}
									variant="contained"
									color="primary"
									disabled={
										agentPartnerCabinetRedirect.submitting ||
										userInfoLoading ||
										areNotificationsLoading ||
										hasAlertDebt
									}
									startIcon={(
										<SvgIcon
											icon="Handshake"
											submitting={agentPartnerCabinetRedirect.submitting || userInfoLoading}
										/>
									)}
								>
									<R id="bt_become_partner" defaultValue="Become a Partner" />
								</Button>
							)}
					{[constants.LIME_LME].some(x => x === env.firm) && userInfo.IsAgent && userInfo.Guid
							&& (
								<Button
									className="QA-PartnerAccount"
									fullWidth
									onClick={actions.partnerCabinetRedirect}
									variant="contained"
									color="primary"
									disabled={agentPartnerCabinetRedirect.submitting}
									startIcon={(
										<SvgIcon
											icon="Handshake"
											submitting={agentPartnerCabinetRedirect.submitting}
										/>
									)}
								>
									<R id="menu_partner_cabinet" defaultValue="Partner Account" />
								</Button>
							)}
				</div>
			</div>
		);
	}
}

SidebarContent.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	agentPartnerCabinetRedirect: PropTypes.object.isRequired,
	userInfo: PropTypes.object.isRequired,
	userInfoLoading: PropTypes.bool.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(SidebarContent);
