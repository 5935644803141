import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { constants, env } from '@config';
import { userAccounts, app } from '@redux';
import { Link } from 'react-router-dom';
import { A, SvgIcon } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { ModalPopups } from '@enums';
import { getAccountProductName, getPlatformFullName } from '@utils';
import { usePaymentGateWidgetHook, useMoneyFormat, useAccountFundingInfo } from '@hooks';
import { Mt4Img, Mt5Img } from '@content/mtLogos';

const useStyles = makeStyles(theme => ({
	rootTop: {
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		paddingBottom: '15px',
	},
	rootMidl: {
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		padding: '15px 14px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	rootBottom: {
		borderBottomLeftRadius: '10px',
		borderBottomRightRadius: '10px',
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		padding: '15px 14px',
		fontSize: '12px',
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',

		},
		padding: '15px 14px',
	},
	leftSection: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: '5px',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '10px',

		},
	},
	middleSection: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: '5px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			width: '315px',
			marginBottom: '10px',
			alignItems: 'center',
		},
	},

	middleSectionRow: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: '5px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			width: '315px',
			marginBottom: '10px',
			alignItems: 'center',
		},
	},
	descriptionBlod: {
		fontWeight: 'Bold',
		[theme.breakpoints.down('sm')]: {
			width: '50%',
		},
	},
	descriptionBlodBlueColor: {
		fontWeight: 'Bold',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			width: '50%',
		},

	},
	descriptionBlodBlueColorTradeLogin: {
		fontWeight: 'Bold',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			width: '50%',
			fontSize: '18px',
		},

	},
	descriptionBlodBlueColorRow: {
		fontWeight: 'Bold',
		color: theme.palette.primary.main,
	},
	wrapper: {
		display: 'flex',
		paddingBottom: '5px',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-between',
			alignItems: 'start',
			width: '315px',
		},
	},
	stat: {
		marginRight: '10px',
		minWidth: '123px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
		},
	},
	buttons: {
		fontSize: '10px',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
		[theme.breakpoints.down('sm')]: {
			width: '160px',
			padding: '5px 0px 5px 0px',
		},
	},
	wrapperRow: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: '5px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
			paddingBottom: '10px',
		},
	},

	statRow: {
		marginRight: '5px',
		width: '90px',
		[theme.breakpoints.down('sm')]: {
			marginRight: '5px',
			width: '48px',
		},
	},
	Mt4Img: {
		width: '50px',
		heigth: '50px',
		borderRadius: '5px',
		boxShadow: '0px 3px 3px 1px #DCDCDC',
	},

	platformFullName: {
		padding: '0px 5px 0px 5px',
		fontSize: '0.875rem',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: '5px',
		boxShadow: '0px 3px 3px 1px #DCDCDC',
	},
	btEdit: {
		margin: '-14px -12px -12px -8px',
	},
	btEditSmallIcon: {
		fontSize: '1rem',
	},
}));

const getAccountActivationSessionStorageKey = (tradeCode) => `AccountActivationRequested_${tradeCode}`

const useActions = () => {
	const dispatch = useDispatch();
	const showLeverageDialog = () => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_ORDER_LEVERAGE));
	const showChangeMtPasswordDialog = () => dispatch(app.actions.showBaseModal(ModalPopups.CY_CHANGE_MT_PASSWORD));
	const activateAccount = (tradeCode) => dispatch(
		userAccounts.actions.activateAccount.request(tradeCode)
	);
	return {
		showLeverageDialog,
		showChangeMtPasswordDialog,
		activateAccount,
	};
};
export const AccountBriefingPanelLME = () => {
	const theme = useTheme();
	const classes = useStyles();
	const moneyFormat = useMoneyFormat();

	const currentAccountData = useSelector(state => userAccounts.selectors.currentAccountData(state));
	const accounts = useSelector(state => state.userAccounts.accounts);
	const resolution = useSelector(app.selectors.resolution);
	const currentUserAccountsInfo = useSelector(userAccounts.selectors.accountInfo);
	const TradeLogin = currentUserAccountsInfo && currentUserAccountsInfo.TradeLogin;
	const tradeCode = currentUserAccountsInfo && currentUserAccountsInfo.TradeCode;
	const LeverageText = currentUserAccountsInfo && currentUserAccountsInfo.LeverageText;
	const serverName = currentUserAccountsInfo && currentUserAccountsInfo.ServerName;
	const loanValue = currentAccountData.baseParams && currentAccountData.baseParams.LoanValue;
	const freeMargin = currentAccountData.baseParams && currentAccountData.baseParams.FreeMargin;
	const balance = currentAccountData.baseParams && currentAccountData.baseParams.Balance;
	const Equity = currentAccountData.baseParams && currentAccountData.baseParams.EquityValue;
	const margin = currentAccountData.baseParams && currentAccountData.baseParams.Margin;
	const accountStatusIsArchive = currentUserAccountsInfo && currentUserAccountsInfo.Status.IsArchive;
	const accountStatusIsClosed = currentUserAccountsInfo && currentUserAccountsInfo.Status.IsClosed;
	const accountStatusIsDisabled = currentUserAccountsInfo && currentUserAccountsInfo.Status.IsDisabled;
	const currentAccount = accounts.find(x => tradeCode === x.TradeCode);
	const isMt4Account = currentAccount && currentAccount.IsForex;
	const terminalMt4Link = `${env.baseUrl}/static/mt4web.html${(TradeLogin && `?login=${TradeLogin}`) ||''}`;
	const terminalMt5Link = `${env.baseUrl}/static/webterminal.html${(TradeLogin && `?login=${TradeLogin}`) || ''}`;
	const currencyCode = currentUserAccountsInfo.MoneyInfo
		&& currentUserAccountsInfo.MoneyInfo[0]
		&& currentUserAccountsInfo.MoneyInfo[0].CurrencyCode;
	const productName = currentAccount && getAccountProductName(currentAccount);
	const accountInfo = useSelector(state => userAccounts.selectors.accountInfo(state));
	const fundInfo = useAccountFundingInfo({ tradeCode: accountInfo.TradeCode, currency: '' });
	const isLimeAgentAccount = currentAccount && currentAccount.IsLimeAgent;
	const [paymentGateWidgetBtnId] = usePaymentGateWidgetHook();
	const actions = useActions();
	return (
		<>
			<div className={classes.rootTop}>
				<div className={classes.container}>
					<div className={classes.middleSection}>
						<div className={classes.wrapper}>
							<div>
								{isMt4Account ? (
									<img className={classes.Mt4Img} src={Mt4Img} alt="" />
								) : (
									<img className={classes.Mt4Img} src={Mt5Img} alt="" />
								)}
							</div>
						</div>
						{resolution === app.CONFIG.RESOLUTIONS_ENUM.xs && (
							<div className={classes.wrapper}>
								<div className={classes.descriptionBlodBlueColor} style={{ fontSize: '18px' }}>
									{moneyFormat(balance, currencyCode)}
								</div>
							</div>
						)}
					</div>
					<div className={classes.middleSection}>
						<div className={classes.wrapper}>
							<div className={classes.descriptionBlodBlueColorTradeLogin} style={{ paddingRight: '10px' }}>
								{TradeLogin}
							</div>
						</div>
						<div className={classes.wrapper}>
							<div>
								<div className={classes.platformFullName} color="primary">{getPlatformFullName(productName)}</div>
							</div>
						</div>
					</div>
					<div className={classes.middleSectionRow}>
						<div className={classes.wrapperRow}>
							<div className={classes.statRow}>
								<R id="common_account_status" defaultValue="Status" />
							</div>
							<Box
								fontWeight={'bold'}
								color={accountStatusIsArchive ? theme.palette.text.disabled : theme.palette.primary.main}
							>
								{currentUserAccountsInfo?.loading ? '-' : (
									accountStatusIsArchive && 'Archive' ||
									accountStatusIsClosed && 'Closed' ||
									accountStatusIsDisabled && 'Disable' ||
									!accountStatusIsDisabled && !accountStatusIsClosed && !accountStatusIsArchive && 'Active'
								)}
							</Box>
						</div>
						<div className={classes.wrapperRow}>

							<div className={classes.statRow}>
								<R id="common_useraccounts_trade_server" defaultValue="Server" />
							</div>
							<div className={classes.descriptionBlodBlueColorRow}>
								{serverName}
							</div>
						</div>
					</div>
					<div className={classes.leftSection}>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="common_account_currency" defaultValue="Account currency" />
							</div>
							<div className={classes.descriptionBlodBlueColor}>
								{currencyCode || '-'}
							</div>
						</div>
						<div className={classes.wrapper}>

							<div className={classes.stat}>
								<R id="common_account_leverage" defaultValue="Leverage" />
							</div>
							<div className={classes.descriptionBlodBlueColor}>
								{LeverageText || '-'}
								{!isLimeAgentAccount && !currentAccount?.IsDemo ? (
									<IconButton
										onClick={actions.showLeverageDialog}
										classes={{ root: classes.btEdit }}
										disabled={currentUserAccountsInfo?.fetchingAccountActivation || currentUserAccountsInfo?.loading || accountStatusIsArchive}
									>
										<SvgIcon
											icon="Edit"
											fontSize="small"
											classes={{ root: classes.btEditSmallIcon }}
											color={accountStatusIsArchive ? "disabled" : "primary" }
										/>
									</IconButton>
								) : null }
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className={classes.rootMidl}>

				<div className={classes.container}>
					<div className={classes.leftSection}>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="shared_balance" defaultValue="Balance" />
							</div>
							<div className={classes.descriptionBlod}>
								{moneyFormat(balance, currencyCode)}
							</div>
						</div>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="account_profile_margin" defaultValue="Margin" />
							</div>
							<div className={classes.descriptionBlod}>
								{moneyFormat(margin, currencyCode)}
							</div>
						</div>
					</div>
					<div className={classes.leftSection}>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="common_loan" defaultValue="Credit" />
							</div>
							<div className={classes.descriptionBlod}>
								{loanValue && loanValue.length ? moneyFormat(loanValue[0].Amount, loanValue[0].CurrencyCode) : '-'}
							</div>
						</div>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="shared_freemargin" defaultValue="Free Margin" />
							</div>
							<div className={classes.descriptionBlod}>
								{moneyFormat(freeMargin, currencyCode)}
							</div>
						</div>
					</div>
					<div className={classes.leftSection}>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="shared_account_equity" defaultValue="Equity" />
							</div>
							<div className={classes.descriptionBlod}>
								{moneyFormat(Equity, currencyCode)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{!currentUserAccountsInfo?.loading && (
				<div className={classes.rootBottom}>
					<div className={classes.container}>
						{currentAccount && !currentAccount.IsDemo &&  !accountStatusIsArchive && (
							<div className={classes.leftSection}>
								<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
									{[constants.LIME_INT, constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)
										&& !isLimeAgentAccount
										&& fundInfo && fundInfo.value && fundInfo.value.WidgetJsScriptUrl && (
										<div id={paymentGateWidgetBtnId} style={{ paddingRight: '10px' }}>
											<Button className={classes.buttons} color="primary" variant="outlined">
												<R id="common_fund" defaultValue="Fund" />
											</Button>
										</div>
									)}
									<div>
										{(accountInfo.MoneyInfo.some(x => x.IsAllowedOut) ? (
											<Link style={{ textDecoration: 'none' }} to="/dashboard/cashmanagement">
												<Button className={classes.buttons} color="primary" variant="outlined">
													<R id="shared_withdraw" defaultValue="Withdraw" />
												</Button>
											</Link>
										) : (
											<Button className={classes.buttons} color="primary" variant="outlined" disabled>
												<R id="shared_withdraw" defaultValue="Withdraw" />
											</Button>
										))}
									</div>
								</div>
							</div>
						)}
						{!accountStatusIsArchive && !isLimeAgentAccount && (
							<div className={classes.leftSection}>
								<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
									<div style={{ paddingRight: '10px' }}>
										<Link style={{ textDecoration: 'none' }} to="/dashboard/tradingplatforms">
											<Button className={classes.buttons} color="primary" variant="outlined">
												<R id="common_menu_trading_platforms" defaultValue="Trading Platforms" />
											</Button>
										</Link>
									</div>
									<div>
										<A href={isMt4Account ? terminalMt4Link : terminalMt5Link} target="_blank">
											<Button className={classes.buttons} color="primary" variant="outlined">
												<R id="common_trade" defaultValue="Trade" />
											</Button>
										</A>

									</div>
								</div>
							</div>
						)}
						{!accountStatusIsArchive && (
							<div className={classes.leftSection}>
								<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
									<div style={{ paddingRight: '10px' }}>
										<Button
											className={classes.buttons}
											color="primary"
											variant="outlined"
											onClick={actions.showChangeMtPasswordDialog}
										>
											<R id="common_change_password_mt" defaultValue="Change password MT" />
										</Button>
									</div>
								</div>
							</div>
						)}
						{accountStatusIsArchive && (
							<div className={classes.leftSection}>
								<Button
									className={classes.buttons}
									color="primary"
									variant="outlined"
									onClick={() => actions.activateAccount({
										tradeCode: currentAccount.TradeCode,
										onEndRequest: () => sessionStorage.setItem(getAccountActivationSessionStorageKey(tradeCode), true) 
									})}
									disabled={currentUserAccountsInfo.fetchingAccountActivation || Boolean(sessionStorage.getItem(getAccountActivationSessionStorageKey(tradeCode)))}
								>
									{currentUserAccountsInfo.fetchingAccountActivation
										? <CircularProgress size={18} style={{ color: theme.palette.primary.main }} />
										: <R id="activate_btn" defaultValue="Activate" />}
								</Button>
							</div>
						)}
						{currentAccount && !currentAccount.IsDemo && (
							<div className={classes.leftSection}>
								<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
									<div style={{ paddingRight: '10px' }}>
										<Link style={{ textDecoration: 'none' }} to="/dashboard/reports">
											<Button className={classes.buttons} color="primary" variant="outlined">
												<R id="common_menu_reports" defaultValue="Reports" />
											</Button>
										</Link>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};
