import React from 'react';
import compose from 'recompose/compose';
import {
	Button,
	FormInputPassword,
	withTranslate,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	requirement: {
		color: theme.palette.text.secondary,
		padding: '0 12px',
		'&:last-of-type': {
			marginBottom: '30px',
		},
	},
}));

const configuration = {
	minLength: 8,
	maxLength: 15,
	// при добавлении спецсимволов, особенно "-", в список убедиться что регулярное выражение не сломается
	allowedSymbols: ['!', '#', '$', '@', '?'],
};

const allowedSymbolsRegExp = new RegExp(`^[a-zA-Z0-9${configuration.allowedSymbols.join('')}]+$`);

const CY_CHANGE_MT_PASSWORD_FORM = 'CY_CHANGE_MT_PASSWORD_FORM';

const CYChangeMtPasswordForm = ({ handleSubmit, onSubmit, invalid, submitting, translate }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
            <Field
                component={FormInputPassword}
                name="NewPassword"
                placeholder={translate('common_password', 'Password')}
                // предотвращает предложение браузера сохранить этот пароль как пароль от ЛК
                inputProps={{ autoComplete: 'one-time-code' }}
            />
            <Field
                component={FormInputPassword}
                name="RepeatPassword"
                placeholder={translate('common_repeat_password', 'Repeat password')}
                // предотвращает предложение браузера сохранить этот пароль как пароль от ЛК
                inputProps={{ autoComplete: 'one-time-code' }}
            />
            <Box className={classes.requirement} mt={2}>
                <R
                    id="change_password_min_length"
                    defaultValue={`Minimum length of ${configuration.minLength} symbols`}
                    translateParameters={{ minLength: configuration.minLength }}
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="change_password_max_length"
                    defaultValue={`Maximum length of ${configuration.maxLength} symbols`}
                    translateParameters={{ maxLength: configuration.maxLength }}
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="change_password_latin"
                    defaultValue="Password must contain Latin characters"
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="change_password_lower"
                    defaultValue="Password must contain lowercase characters"
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="change_password_upper"
                    defaultValue="Password must contain uppercase characters"
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="change_password_digits"
                    defaultValue="Password must contain digits"
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="allowed_special_symbols"
                    defaultValue={`Allowed special symbols: ${configuration.allowedSymbols}`}
                    translateParameters={{ symbols: configuration.allowedSymbols.join(' ') }}
                />
            </Box>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={invalid || submitting}
            >
                {submitting ? (
                    <CircularProgress
                        style={{ color: theme.palette.common.white }}
                        size={18}
                    />
                ) : (
                    <R id="common_change_password" defaultValue="Change password" />
                )}
            </Button>
        </form>
	);
};

export default compose(
	withTranslate,
	reduxForm({
		form: CY_CHANGE_MT_PASSWORD_FORM,
		initialValues: {
			NewPassword: '',
			RepeatPassword: '',
		},
		validate: values => {
			const errors = {};
			if (values.NewPassword !== values.RepeatPassword) {
				errors.RepeatPassword = 'passwords not match';
			}
			Object.entries(values).forEach(([key, value]) => {
				if (!value) {
					errors[key] = 'required';
					return errors;
				}
				if (value.length < configuration.minLength) {
					errors[key] = `minimum length is ${configuration.minLength} symbols`;
					return errors;
				}
				if (value.length > configuration.maxLength) {
					errors[key] = `maximum length is ${configuration.maxLength} symbols`;
					return errors;
				}
				if (!value.match(/[a-z]/gu)) {
					errors[key] = 'password must containt at least 1 lowercase latin symbol';
					return errors;
				}
				if (!value.match(/[A-Z]/gu)) {
					errors[key] = 'password must containt at least 1 uppercase latin symbol';
					return errors;
				}
				if (!value.match(/[0-9]/gu)) {
					errors[key] = 'password must containt at least 1 digit';
					return errors;
				}
				if (!value.match(allowedSymbolsRegExp)) {
					errors[key] = 'password contains not allowed characters';
					return errors;
				}
			});

			return errors;
		},
	}),
)(CYChangeMtPasswordForm);
