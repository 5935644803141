import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { userAccounts } from '@redux';
import compose from 'recompose/compose';
import {
	A, Button, SvgIcon
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { env } from '@config';
import Mt4Img from '@content/mtLogos/mt4-original.png';
import Mt5Img from '@content/mtLogos/mt5-original.png';
import SoftIcons from './soft-icons.png';
import classNames from 'classnames';

const styles = theme => ({
	root: {
		border: '1px solid red',
	},
	header: {
		...theme.customstyles.headline1,
		padding: '8px 0',
	},
	content: {
		...theme.customstyles.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	mr60: {
		marginRight: '60px',
	},
	expansionPanelStaticContent: {
		margin: '10px 24px 5px 0px',
		color: theme.palette.text.secondary,
	},
	expansionPanelContent: {
		'& $expansionPanelRoot': {
			marginLeft: '24px',
		},
		'& $preFieldLabel': {
			marginLeft: '24px',
		},
		'& $noLeftMarginField': {
			marginLeft: '24px',
		},
	},
	preFieldLabel: {},
	noLeftMarginField: {
		marginLeft: '0px',
	},
	expansionPanelRoot: {
		marginLeft: '0px',
	},
	progContainer: {
		display: 'flex',
		marginTop: '16px',
		'&:not(:last-child)': {
			borderBottom: '1px solid #deeaef',
		},
	},
	progLeft: {
		flex: '1 0 100px',

	},
	progRight: {
		flex: '0 0 120px',
		paddingLeft: '20px',
		paddingRight: '10px',
		margin: 'auto',
		'& img': {
			width: '90px',
			height: '90px',
		},

	},
	progIconTitle: {
		fontWeight: '500',
		textAlign: 'center',
		marginBottom: '12px',
	},
	progHeader: {
		fontSize: '18px',
		lineHeight: '24px',
		fontWeight: '500',
		marginBottom: '16px',
	},
	progDescription: {
		'& b': { fontWeight: '500' },
	},
	progButtonsContainer: {
		margin: '16px 0px 8px',
	},
	progButton: {
		margin: '0px 16px 16px 0px',
	},
	softIconMultiterminal: { backgroundPosition: '-438px 0' },
	softIcon: {
		width: '73px',
		height: '73px',
		overflow: 'hidden',
		backgroundImage: `url(${SoftIcons})`,
		backgroundRepeat: 'no-repeat',
		marginRight: '10px',
		display: 'inline-block',
		maxWidth: '100%',
		padding: '4px',
		lineHeight: '1.428571429',
		WebkitTransition: 'all .2s ease-in-out',
		transition: 'all .2s ease-in-out',
	},
});



const mapStateToProps = state => ({
	accounts: userAccounts.selectors.accounts(state),
});

const TRADING_PLATFORM_TABS = {
	MT5: 'MT5',
	FOREX: 'FOREX',
}

class TradingPlatforms extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: TRADING_PLATFORM_TABS.MT5,
		};
	}

	setActiveTab = (event, tabId) => {
		this.setState({ activeTab: tabId });
	};

	render() {
		const {
			classes,
			accounts,
		} = this.props;
		
		return (
			<div className={classes.content}>
				<div>
					<div className={classes.header}>
						<R id="common_menu_trading_platforms" defaultValue="Trading Platforms" />
					</div>
				</div>
				<Tabs
					onChange={this.setActiveTab}
					indicatorColor="primary"
					textColor="primary"
					centered
					value={this.state.activeTab}
				>
					<Tab
						label={<R id="mt5global" defaultValue="MT5 Global" />}
						value={TRADING_PLATFORM_TABS.MT5}
					/>
					<Tab
						label={<R id="forex_and_cfd" defaultValue="Forex and CFD" />}
						value={TRADING_PLATFORM_TABS.FOREX}
					/>
				</Tabs>
				{this.state.activeTab === TRADING_PLATFORM_TABS.MT5 ? (
					<>
						<div className={classes.progContainer}>
							<div className={classes.progLeft}>
								<div className={classes.progHeader}>MetaTrader 5</div>
								<div className={classes.progDescription}>
									<R 
										id="common_tradingplatforms_download_link_pc_mt5"
										defaultValue="MT5 is a perfectly equipped trader's working tool that allows trading on the global financial markets and provides all the necessary tools to analyze price dynamics and use the automated trading programs (Expert Advisors)."
									/>
								</div>
								<div className={classes.progButtonsContainer}>
									<Button
										className={classes.progButton}
										variant="outlined"
										color="primary"
										href="https://download.mql5.com/cdn/web/lime.trading.cy/mt5/just2trade5setup.exe"
										size="small"
										startIcon={<SvgIcon icon="Download" />}
									>
										<R id="shared_download" defaultValue="Download" />
									</Button>
								</div>
							</div>
							<div className={classes.progRight}>
								<img src={Mt5Img} alt="MT5" />
								<div className={classes.progIconTitle}>Desktop</div>
							</div>
						</div>
						<div className={classes.progContainer}>
							<div className={classes.progLeft}>
								<div className={classes.progHeader}>MetaTrader 5 for Android</div>
								<div className={classes.progDescription}>
									<R
										id="common_trade_programs_mt5_android"
										defaultValue="Trading application providing access to trading via Android-based mobile devices. Contains the maximum possible set of instruments required for trading and graphical analysis."
									/>
								</div>
								<div className={classes.progButtonsContainer}>
									<Button
										className={classes.progButton}
										variant="outlined"
										color="primary"
										href="https://download.mql5.com/cdn/mobile/mt5/android?server=Just2Trade-MT5&utm_campaign=Just2Trade"
										size="small"
										startIcon={<SvgIcon icon="Android" />}
									>
										Google play
									</Button>
								</div>
							</div>
							<div className={classes.progRight}>
								<img src={Mt5Img} alt="MT5" />
								<div className={classes.progIconTitle}>Mobile</div>
							</div>
						</div>
						<div className={classes.progContainer}>
							<div className={classes.progLeft}>
								<div className={classes.progHeader}>MetaTrader 5 for iPhone, iPad</div>
								<div className={classes.progDescription}>
									<R
										id="common_trade_programs_mt5_iphone"
										defaultValue="Trading application providing access to trading via iPhone or iPad. Contains the maximum possible set of instruments required for trading and graphical analysis."
									/>
								</div>
								<div className={classes.progButtonsContainer}>
									<Button
										className={classes.progButton}
										variant="outlined"
										color="primary"
										href="https://download.mql5.com/cdn/mobile/mt5/ios?server=Just2Trade-MT5&utm_campaign=Just2Trade"
										size="small"
										startIcon={<SvgIcon icon="Apple" />}
									>
										App store
									</Button>
								</div>
							</div>
							<div className={classes.progRight}>
								<img src={Mt5Img} alt="MT5" />
								<div className={classes.progIconTitle}>Mobile</div>
							</div>
						</div>
						<div className={classes.progContainer}>
							<div className={classes.progLeft}>
								<div className={classes.progHeader}>
									<R id="common_useraccounts_trade_metaquotes_mt5" defaultValue="MetaTrader 5 WebTerminal" />
								</div>
								<div className={classes.progDescription}>
									<R id="common_useraccounts_trade_metaquotes_mt5_info" defaultValue="Original web terminal from developers of MT5 by MetaQuotes provides opportunity to trade via any browser in any operating system (Windows, Mac, Linux). It is highly reliable and fully compatible with MT5 ecosystem." />
								</div>
								<div className={classes.progButtonsContainer}>
									<Button
										className={classes.progButton}
										variant="outlined"
										color="primary"
										href={"https://mt5.j2t.global"}
										target="_blank"
										size="small"
										startIcon={<SvgIcon icon="Launch" />}
									>
										<R id="common_useraccounts_trade_metaquotes_mt5" defaultValue="MetaTrader 5 WebTerminal" />
									</Button>
								</div>
							</div>
							<div className={classes.progRight}>
								<img src={Mt5Img} alt="MT5" />
								<div className={classes.progIconTitle}>Web</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className={classes.progContainer}>
							<div className={classes.progLeft}>
								<div className={classes.progHeader}>MetaTrader 4</div>
								<div className={classes.progDescription}>
									<R 
										id="common_tradingplatforms_download_link_pc"
										defaultValue="<b>PC</b> — Download <downloadmt>MetaTrader</downloadmt> (4.27 Mb)."
										enableHtml
										components={{
											downloadmt: <A href="https://download.mql5.com/cdn/web/lime.trading.cy/mt4/just2trade4setup.exe" />,
										}}
									/>
								</div>
								<div className={classes.progButtonsContainer}>
									<Button
										className={classes.progButton}
										variant="outlined"
										color="primary"
										href="https://download.mql5.com/cdn/web/lime.trading.cy/mt4/just2trade4setup.exe"
										size="small"
										startIcon={<SvgIcon icon="Download" />}
									>
										<R id="shared_download" defaultValue="Download" />
									</Button>
								</div>
							</div>
							<div className={classes.progRight}>
								<img src={Mt4Img} alt="MT4" />
								<div className={classes.progIconTitle}>Desktop</div>
							</div>
						</div>
						<div className={classes.progContainer}>
							<div className={classes.progLeft}>
								<div className={classes.progHeader}>
									<R id="common_useraccounts_trade_metaquotes_mt4" defaultValue="MetaTrader 4 WebTerminal" />
								</div>
								<div className={classes.progDescription}>
									<R id="common_useraccounts_trade_metaquotes_mt4_info" defaultValue="Original web terminal from developers of MT4 by MetaQuotes provides opportunity to trade via any browser in any operating system (Windows, Mac, Linux). It is highly reliable and fully compatible with MT4 ecosystem." />
								</div>
								<div className={classes.progButtonsContainer}>
									{accounts && accounts.filter(acc => !acc.IsClosed && acc.IsEnabledForTrade && acc.IsForex).map((account, index) => (
										<Button
											className={classes.progButton}
											key={index}
											variant="outlined"
											color="primary"
											href={`${env.baseUrl}/static/mt4web.html${(account.Login && `?login=${account.Login}`) || ''}`}
											target="_blank"
											size="small"
											startIcon={<SvgIcon icon="Launch" />}
										>
											{account.DisplayFullName}
										</Button>
									))}
								</div>
							</div>
							<div className={classes.progRight}>
								<img src={Mt4Img} alt="MT4" />
								<div className={classes.progIconTitle}>Web</div>
							</div>
						</div>
						<div className={classes.progContainer}>
							<div className={classes.progLeft}>
								<div className={classes.progHeader}>MetaTrader 4 for Android</div>
								<div className={classes.progDescription}>
									<R
										id="common_trade_programs_forex_android"
										defaultValue="<b>MetaTrader 4 for Android</b> (System Requirements: Android 5.0 or later) – Version of Metatrader 4 to trade on smartphone or tablet-PC. To install the application you need to access <downloadlink>Android Market</downloadlink> from your Android-device. You should use login/password from your account to enter the platform."
										enableHtml
										components={{
											downloadlink: <A href="https://download.mql5.com/cdn/mobile/mt4/android?server=Just2Trade-Test,Just2Trade-Test3,Just2Trade-Test2,Just2Trade-Test4&utm_campaign=Just2Trade" />
										}}
									/>
								</div>
								<div className={classes.progButtonsContainer}>
									<Button
										className={classes.progButton}
										variant="outlined"
										color="primary"
										href="https://download.mql5.com/cdn/mobile/mt4/android?server=Just2Trade-Test,Just2Trade-Test3,Just2Trade-Test2,Just2Trade-Test4&utm_campaign=Just2Trade"
										size="small"
										startIcon={<SvgIcon icon="Android" />}
									>
										Google play
									</Button>
									<Button
										className={classes.progButton}
										variant="outlined"
										color="primary"
										href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/metatrader4.apk"
										size="small"
										startIcon={<SvgIcon icon="Download" />}
									>
										<R id="shared_download" defaultValue="Download" />
									</Button>
								</div>
							</div>
							<div className={classes.progRight}>
								<img src={Mt4Img} alt="MT4" />
								<div className={classes.progIconTitle}>Mobile</div>
							</div>
						</div>
						<div className={classes.progContainer}>
							<div className={classes.progLeft}>
								<div className={classes.progHeader}>MetaTrader 4 for iPhone, iPad</div>
								<div className={classes.progDescription}>
									<R
										id="common_trade_programs_forex_iphone"
										defaultValue="<b>MetaTrader 4 for iPhone, iPad</b> – (System Requirements:  iOS 3.0 or later) To install the application download it via your device from <downloadlink>Арр Store</downloadlink>."
										enableHtml
										components={{
											downloadlink: <A href="https://download.mql5.com/cdn/mobile/mt4/ios?server=Just2Trade-Test,Just2Trade-Test3,Just2Trade-Test2,Just2Trade-Test4&utm_campaign=Just2Trade" />,
										}}
									/>
								</div>
								<div className={classes.progButtonsContainer}>
									<Button
										className={classes.progButton}
										variant="outlined"
										color="primary"
										href="https://download.mql5.com/cdn/mobile/mt4/ios?server=Just2Trade-Test,Just2Trade-Test3,Just2Trade-Test2,Just2Trade-Test4&utm_campaign=Just2Trade"
										size="small"
										startIcon={<SvgIcon icon="Apple" />}
									>
										App store
									</Button>
								</div>
							</div>
							<div className={classes.progRight}>
								<img src={Mt4Img} alt="MT4" />
								<div className={classes.progIconTitle}>Mobile</div>
							</div>
						</div>
						<div className={classes.progContainer}>
						<div className={classes.progLeft}>
							<div className={classes.progHeader}>MultiTerminal</div>
							<div className={classes.progDescription}>
								<R
									id="common_trade_programs_forex_multiterminal"
									defaultValue="<b>MultiTerminal</b> — Download <downloadlink>MultiTerminal Installer</downloadlink> (1.21 Mb)."
									enableHtml
									components={{
										downloadlink: <A href="https://download.mql5.com/cdn/web/lime.trading.cy/mt4/just2trade4multisetup.exe" />,
									}}
								/>
							</div>
							<div className={classes.progButtonsContainer}>
								<Button
									className={classes.progButton}
									variant="outlined"
									color="primary"
									href="https://download.mql5.com/cdn/web/lime.trading.cy/mt4/just2trade4multisetup.exe"
									size="small"
									startIcon={<SvgIcon icon="Download" />}
								>
									<R id="shared_download" defaultValue="Download" />
								</Button>
							</div>
						</div>
						<div className={classes.progRight}>
							<span className={classNames(classes.softIcon, classes.softIconMultiterminal)}>&nbsp;</span>
						</div>
					</div>
					</>
				)}
			</div>
		);
	}
}

TradingPlatforms.defaultProps = {
};

TradingPlatforms.propTypes = {
	classes: PropTypes.object.isRequired,
};


export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, null),
)(TradingPlatforms);
